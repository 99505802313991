
import { Component, Vue } from 'vue-property-decorator'
import { uploadBatchUrl, TUploadResult } from '@/api/candidate'
import { UserModule } from '@/store/modules'
import type { Res } from '@/utils/request'
import type { UploadFile } from "@/utils/interface";
@Component({
  name: 'UploadBatch',
})
export default class extends Vue {
  /** 上传地址 */
  uploadUrl = uploadBatchUrl

  fileList = [] as UploadFile[]

  /** 自定义头部 */
  get myHeaders() {
    return {
      'x-auth-token': UserModule.token,
    }
  }

  /** 上传成功 */
  onSuccess(res: Res<TUploadResult>, file: UploadFile, fileList: UploadFile[]) {
    // console.log('res----', res)
    if (res.code === 0) {
      const { batchId, num } = res.data
      this.$message({ message: `成功上传${num}位候选人`, type: 'success' })
      this.$emit('upload', { batchId, num })
    } else {
      this.$message({ message: res.message, type: 'error' })
    }
  }

  /** 上传失败 */
  onError(err: Error, file: UploadFile, fileList: UploadFile[]) {
    console.log('ee', err)
  }

  onChange(file: UploadFile, fileList: UploadFile[]) {
    /** 过滤有问题的文件 */
    fileList = fileList.filter(i => !i.response || i.response.code === 0);
    /** 只保留最后一个文件 */
    this.fileList = fileList.slice(-1)
  }
}
