
import { Component, Vue } from 'vue-property-decorator'
import { progressTypeFilter, parseTime, getOptions, progressMap } from '@/utils/filters'
import { OrderSurveyInfo, saveOrderBatch, MBatchOrderInfo } from '@/api/order'

import InputSearch from '@/components/InputSearch.vue'
import CandidateSelect from './candidateSelect.vue'
import CheckSetting from './checkSetting/index.vue'
import Checked from './checked.vue'

import type { IOption } from '@/utils/interface'

@Component({
  components: { InputSearch, CandidateSelect, CheckSetting, Checked },
  name: 'Check',
  filters: {
    progressTypeFilter,
    parseTime,
  },
})
export default class extends Vue {
  /** 当前步骤 */
  active = 0
  /** 是否批量 */
  isBatch = false
  /** 是否调查工作经历 */
  isExperience = false
  listLoading = true
  listQuery = {
    pageNum: 1,
    pageSize: 20,
  }

  progressList: IOption[] = getOptions(progressMap)

  /** 背调候选人数量（批量背调时有效） */
  checkNum = 0

  /** 背调预估总价 */
  checkPrice = 0

  /** 背调预估时间 */
  checkTime = 0

  /** 上一步 */
  onPrev() {
    this.active -= 1
  }

  /** 下一步 */
  onNext() {
    switch (this.active) {
      case 0: {
        this.gotoSetting()
        break
      }
      case 1: {
        this.onSaveBatch()
        break
      }
      default:
        break
    }
  }

  /** 跳转到背调设置页 */
  gotoSetting() {
    ;(this.$refs.candidateSelect as CandidateSelect)
      .validate()
      .then(val => {
        // console.log('gotoSetting', val)
        if (this.isBatch && this.checkNum === 0) {
          this.$message({ message: '请选择候选人', type: 'warning' })
        } else {
          this.active = 1
          ;(this.$refs.checkSetting as CheckSetting).setData(val)
        }
      })
      .catch(err => {
        console.log('error gotoSetting!!', err)
      })
  }

  onItemUpdate(itemList: OrderSurveyInfo[], form: MBatchOrderInfo) {
    // console.log('onItemUpdate', itemList, form)
    /** 总价 */
    let price = 0
    /** 并行时间(取最大值) */
    let timeBX = 0
    /** 串行时间(求和) */
    let timeCX = 0
    itemList.forEach(i => {
      // 数量
      let num = i.num
      // 价格
      if (/学历/.test(i.surveyName)) {
        num = form.xlList.length
      } else if (/学位/.test(i.surveyName)) {
        num = form.xwList.length
      }
      if (/工作履历/.test(i.surveyName) || /工作表现/.test(i.surveyName)) {
        num = form.companyIds.length
      }
      price += i.price * num
      // price += i.surveyPrice * num
      // 时间
      if (i.calculateType === '00') {
        timeBX = Math.max(timeBX, i.feedbackTime)
      } else if (i.calculateType === '01') {
        timeCX += i.feedbackTime * num
      }
    })
    this.checkPrice = price
    // 取较大的值
    this.checkTime = Math.ceil(Math.max(timeBX, timeCX) / 8)
  }

  /** 批量保存 */
  onSaveBatch() {
    ;(this.$refs.checkSetting as CheckSetting)
      .validate()
      .then(val => {
        // console.log('batch', val)
        // 后端还会做校验的
        val.price = this.checkPrice
        val.preFeedbackTime = this.checkTime
        val && saveOrderBatch(val).then(() => {
          this.active = 2
          this.$message({ message: '保存成功', type: 'success' })
        })
      })
      .catch(() => {
        console.log('error checkSetting!!')
      })
  }
}
