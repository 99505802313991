
import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import { OptionModule } from '@/store/modules'
import XCascader from './XCascader.vue'
import JobEditModal from '@/views/jobs/jobEditModal.vue'

@Component({
  name: 'JobSelect',
  components: { XCascader, JobEditModal },
})
export default class extends Vue {
  /** 选中的值 */
  @Model('change', { type: String, default: '' }) value!: string
  @Prop({ default: '请选择' }) placeholder!: string
  get values() {
    return this.value
  }

  /** 选项 */
  get jobList() {
    return OptionModule.jobList
  }

  getOptions() {
    OptionModule.getJobList()
  }

  onAdd() {
    ;(this.$refs.edit as JobEditModal).show()
  }

  /** 点击 */
  onChange(val: []) {
    // 选择之后将下拉界面收起
    setTimeout(() => (this.$refs.cascader as any).toggleDropDownVisible(), 100)
    this.$emit('change', val)
  }

  /** 切换显示 */
  onVisibleChange(visible: boolean) {
    const ref = this.$refs.select as any
    const panel = ref.$children[1].$el as Element
    console.log('pp', visible, ref, panel)
    const container = panel.querySelector('.el-scrollbar__view') as Element
    if (visible && !container.querySelector('.x-select-btn')) {
      const el = document.createElement('div')
      el.className = 'x-select-btn'
      el.innerText = '新建职位'
      el.onclick = () => {
        this.onAdd()
      }
      container.insertBefore(el, container.firstChild)
    }
  }

  onUpdate({ positionId, officeIds }: any) {
    this.$emit('change', positionId)
    this.$emit('changeOffice', officeIds)

    this.getOptions()
  }

  created() {
    this.getOptions()
  }
}
