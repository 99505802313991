
import { Component, Vue } from 'vue-property-decorator'
import ImgDialog from '@/components/ImgDialog.vue'
import { UserModule } from '@/store/modules'

@Component({
  name: 'Checked',
  components: { ImgDialog },
})
export default class extends Vue {
  /** 弹窗是否可见 */
  qrCodeVisible = false

  /** 助理信息 */
  get info() {
    return UserModule.assistantInfo
  }

  /** 显示二维码弹窗 */
  showQrCode() {
    this.qrCodeVisible = true
  }

  /** 跳转到订单页面 */
  gotoList() {
    this.$router.push({ name: 'order' })
  }
}
