
import { Component, Vue } from 'vue-property-decorator'
import { saveJob } from '@/api/jobs'
import XBox from '@/components/XBox.vue'
import OfficeSelect from '@/components/OfficeSelect.vue'
import JobBaseSelect from '@/components/JobBaseSelect.vue'
import AcctSelect from '@/components/AcctSelect.vue'
import type { JobInfo } from '@/api/jobs'
import type { ElForm } from 'element-ui/types/form'
import { findOption, getJobBaseIds, getLastItem } from '@/utils/common'
import { OptionModule } from '@/store/modules'

@Component({
  components: { XBox, OfficeSelect, JobBaseSelect, AcctSelect },
  name: 'OfficeEditModal',
})
export default class extends Vue {
  /** 弹窗是否可见 */
  dialogVisible = false

  /** 数据 */
  form = {} as JobInfo

  rules = {
    baseIds: [{ type: 'array', required: true, message: '请选择职位类型', trigger: 'change' }],
    positionName: [{ required: true, message: '请输入职位名称', trigger: 'blur' }],
    ownerOfficeId: [{ required: true, message: '请选择所属部门', trigger: 'change' }],
    contactAcct: [{ required: true, message: '请输入背调负责人', trigger: 'change' }],
  }

  /** 标题 */
  get title() {
    return `${this.form.positionId ? '编辑' : '新增'}职位`
  }

  /** 部门数据 */
  get officeList() {
    return OptionModule.officeList
  }

  /** 显示 */
  show(data = {} as JobInfo) {
    data.baseIds = getJobBaseIds(data.baseId)
    this.form = data
    this.dialogVisible = true
  }

  /** 确认 */
  onOk() {
    ;(this.$refs.form as ElForm).validate(valid => {
      // console.log('valid', valid, this.form)
      if (valid) {
        const officeIds = this.form.ownerOfficeId
        this.form.baseId = getLastItem(this.form.baseIds)
        this.form.ownerOfficeId = getLastItem(this.form.ownerOfficeId)
        this.form.ownerOfficeName = findOption(this.officeList, this.form.ownerOfficeId).text
        saveJob(this.form).then(positionId => {
          this.$message({ message: '保存成功', type: 'success' })
          this.dialogVisible = false
          this.$emit('update', { officeIds, positionId })
        })
      } else {
        console.log('error submit!!')
      }
    })
  }
}
