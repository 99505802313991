
import { Component, Vue } from 'vue-property-decorator'
import { validatePhoneNumber, validateEmail } from '@panstar/common'
import { CandidateInfo, downloadTemplate, saveCandidate, TUploadResult, uploadBatchUrl } from '@/api/candidate'
import { BatchOrderInfo } from '@/api/order'
import InputSearch from '@/components/InputSearch.vue'
import XBox from '@/components/XBox.vue'
import UploadResume from './uploadResume.vue'
import UploadBatch from './uploadBatch.vue'
import OfficeSelect from '@/components/OfficeSelect.vue'
import JobSelect from '@/components/JobSelect.vue'

import type { IOption } from '@/utils/interface'
import type { ElForm } from 'element-ui/types/form'
import { OptionModule, UserModule } from '@/store/modules'

@Component({
  components: { InputSearch, XBox, UploadResume, UploadBatch, OfficeSelect, JobSelect },
  name: 'CandidateSelect',
})
export default class extends Vue {
  /** 数据 */
  form: {
    /** 是否批量 */
    isBatch: boolean
    /** 是否调查工作经历 */
    isExperience: boolean
  } = {
    isBatch: false,
    isExperience: false,
  }

  layout = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 12,
    xl: 12,
  }

  /** 单候选人数据 */
  formSingle = ({
    candidateId: undefined,
    candidateName: undefined,
    resumeId: undefined,
    phone: undefined,
    email: undefined,
  } as unknown) as CandidateInfo

  /** 批量数据 */
  formBatch = ({
    batchId: undefined,
  } as unknown) as BatchOrderInfo

  /** 职位数据 */
  get jobList() {
    return OptionModule.jobList
  }

  /** 套餐数据 */
  packageList: IOption[] = []
  /** 调查项数据 */
  itemList: IOption[] = []

  uploadBatchUrl = uploadBatchUrl
  /** 校验规则 */
  ruleBatch = {
    batchId: [{ required: true, message: '请上传候选人名单', trigger: 'change' }],
  }

  /** 校验规则 */
  get ruleSingle() {
    const rule = {
      candidateName: [{ required: true, message: '请输入候选人姓名', trigger: 'blur' }],
      phone: [
        { required: true, message: '请输入候选人手机号', trigger: 'blur' },
        { validator: validatePhoneNumber, trigger: 'blur' },
      ],
      email: [
        // { required: true, message: '请输入候选人邮箱', trigger: 'blur' },
        { validator: validateEmail, trigger: 'blur' },
      ],
      officeIds: [{ required: true, message: '请选择用人部门', trigger: 'change' }],
      positionId: [{ required: true, message: '请选择应聘职位', trigger: 'change' }],
      resumeId: [] as object[],
    }
    if (this.form.isExperience) {
      rule.resumeId = [{ required: true, message: '请上传候选人简历', trigger: 'change' }]
    }
    return rule
  }

  /** 自定义头部 */
  get myHeaders() {
    return {
      'x-auth-token': UserModule.token,
    }
  }

  /** 下载批量模版 */
  onDownloadTemplate() {
    downloadTemplate()
  }

  /** 简历上传成功 */
  onUploadResume(data: CandidateInfo) {
    this.formSingle.resumeId = data.resumeId
    this.formSingle.candidateId = data.candidateId
    this.formSingle.candidateName = data.candidateName
    this.formSingle.phone = data.phone
    this.formSingle.email = data.email

    this.$emit('numChange', 1)
    ;(this.$refs.formSingle as ElForm).validateField(['resumeId', 'candidateName', 'phone', 'email'])
  }

  /** 批量上传成功 */
  onUploadBatch({ batchId, num }: TUploadResult) {
    this.formBatch.batchId = batchId
    this.$emit('numChange', num)
    ;(this.$refs.formBatch as ElForm).validateField(['batchId'])
  }

  onBatchChange(val: Boolean) {
    this.formBatch.batchId = ''
    this.$emit('numChange', 0)
    this.$emit('IsBatchChange', val)
  }

  /** 切换是否需要工作经历 */
  onExperienceChange(val: Boolean) {
    ;(this.$refs.formSingle as ElForm).clearValidate()
    this.$emit('IsExperienceChange', val)
  }

  onChangeOffice(officeIds: string[]) {
    this.formSingle.officeIds = officeIds
  }

  /** 校验数据 */
  validate() {
    return new Promise<BatchOrderInfo>((resolve, reject) => {
      if (this.form.isBatch) {
        ;(this.$refs.formBatch as ElForm).validate((valid, invalidFields) => {
          console.log('valid', valid, this.formBatch, OptionModule.officeList, OptionModule.jobList)
          if (valid) {
            resolve(this.formBatch)
          } else {
            reject(invalidFields)
          }
        })
      } else {
        ;(this.$refs.formSingle as ElForm).validate((valid, invalidFields) => {
          // console.log('valid', valid, this.formSingle, OptionModule.officeList, OptionModule.jobList)
          if (valid) {
            saveCandidate(this.formSingle)
              .then(candidateId => {
                const data = {
                  candidateList: [candidateId],
                } as BatchOrderInfo
                resolve(data)
              })
              .catch(error => {
                reject(error)
              })
          } else {
            reject(invalidFields)
          }
        })
      }
    })
  }
}
